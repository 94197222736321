/*global angular*/
'use strict'

angular.module('pizzahunt').directive('phCartItem', function () {
  return {
    replace: true,
    scope: {
      item: '=',
      updateQuantity: '='
    },
    templateUrl: 'components/ph-cart-item/ph-cart-item.html',
    controllerAs: 'cartItemCtrl',
    bindToController: true,
    controller: function ($element, $scope) {
      const $ = angular.element
      let cartItemCtrl = this
      let el = $element[0]

      // Uses the background image method used by http://shopify.github.io/js-buy-sdk/examples/#showhide-cart
      let image = el.getElementsByClassName('cart-item__img')
      $(image).css('background-image', 'url(' + cartItemCtrl.item.imageSrc + ')')

      // Quantity decrement button
      let quantityDecrement = el.getElementsByClassName('quantity-decrement')
      $(quantityDecrement).on('click', () => {
        cartItemCtrl.updateQuantity(cartItemCtrl.item.variantId, cartItemCtrl.item.quantity - 1)
      })

      // Quantity increment button
      let quantityIncrement = el.getElementsByClassName('quantity-increment')
      $(quantityIncrement).on('click', () => {
        cartItemCtrl.updateQuantity(cartItemCtrl.item.variantId, cartItemCtrl.item.quantity + 1)
      })
    }
  }
})

/*global angular, $*/
'use strict'

import _ from 'lodash-node/modern/collection'

angular.module('pizzahunt').directive('phSlider', function (elementService) {
  return {
    transclude: true,
    replace: true,
    templateUrl: 'components/ph-slider/ph-slider.html',
    link (scope, element, attrs) {
      const modifiers = elementService.getComponentAttrs(attrs, 'modifier')

      if (modifiers) {
        _.forEach(modifiers, (modifier) => attrs.$addClass(`wh-image--${modifier}`))
      }
    },
    controller ($element) {
      // $ jquery is used in this directive because it is a wrapper for glidejs carousel plugin
      // We used this plugin for speed of development. Docs here http://glide.jedrzejchalubek.com/
      let $elementAsJqueryNode = $($element[0])
      $elementAsJqueryNode.glide({
        type: 'carousel',
        animationDuration: 1500,
        autoplay: 8000,
        centered: false
      })

      // @todo pass and insert the wh-slider slides in as `glide__slide` elements
    }
  }
})

/*global angular*/
'use strict'

import extend from 'lodash/object/extend'

/**
 * Cart Item model – Represents the cart item to display
 */
angular.module('pizzahunt').factory('CartItem', function (textService) {
  function CartItem (config) {
    config = config || {}

    this.imageSrc = config.image.src
    this.variantTitle = config.variant_title
    this.title = config.title

    this.variantId = config.variant_id
    this.quantity = config.quantity

    this.price = config.line_price
    this.displayPrice = textService.formatAsMoney(this.price)
  }

  extend(CartItem.prototype, {
    // no methods for now
  })

  return CartItem
})

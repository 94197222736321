/*global angular*/
'use strict'

// Pass a signal id of a container that you want to signal that the element with this directive on it is hovered
angular.module('pizzahunt').directive('phHover', function () {
  return {
    restrict: 'A',
    scope: {
      hoverSignalId: '='
    },
    link (scope, element) {
      const $ = angular.element
      let $element = $(element)

      $element.addClass('ph-hover-element')
      let $signal = $(document.getElementById(scope.hoverSignalId))

      $element.bind('mouseenter', function () {
        $signal.addClass('ph-hover-signal')
      })

      $element.bind('mouseleave', function () {
        $signal.removeClass('ph-hover-signal')
      })
    }
  }
})

/*global angular*/
'use strict'

/**
 * Service providing utility functions for text
 */
angular.module('pizzahunt').service('textService', function () {
  /**
   * Formats given amount as money
   *
   * @method formatAsMoney
   * @param  {String} amount
   *         The amount to format to money
   *
   * @return {String} Amount formatted as money
   */
  this.formatAsMoney = (amount) => {
    return '$' + parseFloat(amount, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').toString() + ' AUD'
  }

  /**
   * Removes return character(s) from passed string
   *
   * @method removeReturnCharacter
   * @param  {String} string
   *         The string to remove return character(s) from
   *
   * @return {String} Clean string with return character(s) removed
   */
  this.removeReturnCharacter = (string) => {
    return string.replace(/(\r\n|\n|\r)/gm, '')
  }
})

'use strict'

import './ph-hover/ph-hover'
import './ph-pizza-cursor/ph-pizza-cursor'
import './ph-loading/ph-loading'
import './ph-product/ph-product'
import './ph-cart/ph-cart'
import './ph-cart-item/ph-cart-item'
import './ph-cart-tab/ph-cart-tab'
import './ph-slider/ph-slider'

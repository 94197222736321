/*global angular, Image*/
'use strict'

import _ from 'lodash-node/modern/collection'

// Concept based off https://github.com/alexanderdickson/waitForImages/blob/master/src/jquery.waitforimages.js
angular.module('pizzahunt').directive('phLoading', function (imagesService) {
  return {
    restrict: 'A',
    compile ($element, attrs) {
      let allImgsLength = 0
      let allImgsLoaded = 0
      let allImgs = []

      $element.addClass('ph-loading')

      const whenAllImagesLoaded = () => {
        $element.removeClass('ph-loading')
      }

      // Populate allImgs object with all valid img elements within this container
      // @note only dealing with img elements for now, can later extend to support background images etc too
      _.forEach(imagesService.getImgElements($element), (img) => {
        let $img = angular.element(img)
        let source = $img.attr('src')

        allImgs.push({
          src: source,
          element: img
        })
      })

      allImgsLength = allImgs.length

      if (allImgsLength === 0) {
        console.log('no images found, calling when loaded')
        whenAllImagesLoaded()
        return
      }

      _.forEach(allImgs, (value) => {
        var image = new Image()
        var $image = angular.element(image)

        // Handle the image loading and error with the same callback.
        $image.on('load', function (event) {
          allImgsLoaded++

          if (allImgsLoaded === allImgsLength) {
            console.log('all images loaded')
            whenAllImagesLoaded()
            return false
          }
        })

        image.src = value.src
      })
    }
  }
})

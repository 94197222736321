/*global angular, Image*/
'use strict'

import _ from 'lodash-node/modern/collection'

/**
 * Service providing utility functions for images
 */
angular.module('pizzahunt').service('imagesService', function () {
  /**
   * Gets the valid & not yet loaded `img` elements, within the given element
   *
   * @method getImgElements
   * @param  {Element} element
   *         The element to check within
   *
   * @return {Array} Collection of valid image elements
   */
  this.getImgElements = (element) => {
    const allImgElements = element.find('img')

    // Filter only valid img elements
    const validImgElements = _.filter(allImgElements, (obj) => {
      let $obj = angular.element(obj)
      let src = $obj.attr('src')

      // Ensure we are dealing with an `img` element with a valid `src` attribute.
      if (src === '') {
        return false
      }

      // Firefox's `complete` property will always be `true` even if the image has not been downloaded.
      // Doing it this way works in Firefox.
      var img = new Image()
      img.src = obj.src
      return !img.complete
    })

    console.log('# img elements = ', validImgElements.length)
    return validImgElements
  }
})

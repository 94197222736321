/*global angular*/
'use strict'

angular.module('pizzahunt').directive('phCart', function (shopifyManager) {
  return {
    replace: true,
    scope: {
      cart: '=',
      items: '=',
      price: '=',
      count: '='
    },
    templateUrl: 'components/ph-cart/ph-cart.html',
    bindToController: true,
    controllerAs: 'cartCtrl',
    controller: function ($scope) {
      let cartCtrl = this
      cartCtrl.cartOpen = false

      // Show cart tab if cart is closed and items are in it (tab showing)
      // or show cart if passed itemCount is more than 0
      let checkShowCartTab = (itemCount) => {
        let tabShowing = (!cartCtrl.cartOpen && cartCtrl.count > 0)
        if (tabShowing || itemCount > 0) {
          cartCtrl.tabShowing = true
        } else {
          cartCtrl.tabShowing = false
        }
      }

      // Close cart, show tab with item count
      cartCtrl.closeCart = () => {
        cartCtrl.cartOpen = false
        checkShowCartTab()
      }

      // Open cart
      cartCtrl.openCart = () => {
        cartCtrl.cartOpen = true
        cartCtrl.tabShowing = false
      }
      $scope.$on('open-cart', () => {
        cartCtrl.openCart()
      })

      // Check for cart items once init
      $scope.$on('init-cart', (event, args) => {
        checkShowCartTab(args.itemCount)
      })

      // Cart checkout
      cartCtrl.checkout = () => {
        window.open(cartCtrl.cart.checkoutUrl, '_self')
      }

      // Update quantity
      cartCtrl.updateQuantity = (variantId, quantity) => {
        shopifyManager.updateQuantity(variantId, quantity)
          .then(() => {
            $scope.$emit('update-cart')
            $scope.$broadcast('update-quantity')
          })
      }
    }
  }
})

/*global angular, ShopifyBuy, localStorage*/
'use strict'

import _ from 'lodash-node/modern/collection'

/**
 * Responsible for managing shopify product buy buttons & cart
 *
 * @module shopifyManager
 */
angular.module('pizzahunt').factory('shopifyManager', function ($q, Product, CartItem) {
  class ShopifyManager {
    /**
     * Variables in this manager
     */
    // this.client
    // this.products
    // this.cart
    // this.cartItems

    /**
     * Initialises the Shopify manager
     * Build new Shopify client & initialise cart
     * Returns a promise
     */
    init () {
      let defer = $q.defer()

      // Init Shopify client
      this.client = ShopifyBuy.buildClient({
        apiKey: '4414b797cbf52e3dafd4c0d3e0f77f38',
        domain: 'pizza-hunt.myshopify.com',
        appId: '6'
      })

      this._initCart()
        .then(() => this._fetchProducts())
        .then(() => this._populateCartItems())
        .then(() => defer.resolve())

      return defer.promise

      // @todo bind event listeners
      //  - escape key closes cart
    }

    /**
     * Add passed variant to cart and populate cart items
     * Returns a promise
     */
    addToCart (selectedVariant) {
      let defer = $q.defer()

      this._addToCart(selectedVariant)
        .then(() => this._populateCartItems())
        .then(() => defer.resolve())

      return defer.promise
    }

    /**
     * Update quantity of passed variant to passed quantity
     */
    updateQuantity (variantId, quantity) {
      let defer = $q.defer()

      let cartItem = this._findCartItemByVariantId(variantId)
      if (cartItem) {
        this._updateItemInCart(cartItem.id, quantity)
          .then(() => this._populateCartItems())
          .then(() => defer.resolve())
      }

      return defer.promise
    }

    // ============================== Private API ============================== //

    /**
     * Initialise the cart
     * Creates a fresh cart or loads a saved cart from localStorage
     * Returns a promise
     */
    _initCart () {
      let lastCartId = localStorage.getItem('lastCartId')

      if (lastCartId) {
        return this.client.fetchCart(lastCartId)
          .then((lastCart) => {
            let savedCart = () => {
              this.cart = lastCart
            }
            return $q.when(savedCart())
          })
      } else {
        return this.client.createCart()
          .then((newCart) => {
            let freshCart = () => {
              this.cart = newCart
              localStorage.setItem('lastCartId', this.cart.id)
            }
            return $q.when(freshCart())
          })
      }
    }

    /**
     * Add passed variant to cart (or increment quantity of variant if already in the cart)
     * Returns a promise
     */
    _addToCart (variant) {
      let variantId = variant.id
      let cartItem = this._findCartItemByVariantId(variantId)
      let quantity = (cartItem ? cartItem.quantity + 1 : 1)

      // Cart item is undefined if variant doesn't already exist in cart
      if (cartItem) {
        return this._updateItemInCart(cartItem.id, quantity)
      } else {
        return this._addItemToCart(variant, quantity)
      }
    }

    /**
     * Add passed variant as item to cart at passed quantity
     * Returns a promise
     */
    _addItemToCart (variant, quantity) {
      return this.cart.addVariants({
        variant: variant,
        quantity: quantity
      })
    }

    /**
     * Update existing item in cart to passed quantity
     * Returns a promise
     */
    _updateItemInCart (itemId, quantity) {
      return this.cart.updateLineItem(itemId, quantity)
    }

    /**
     * Fetches the products from the client
     * Populates the products array
     * Returns a promise
     */
    _fetchProducts () {
      // @note instead of fetching all products we fetch products from the "Pizza Hunt Site Products"
      // collection via id, see them here https://pizza-hunt.myshopify.com/admin/collections/264503171
      // return this.client.fetchAllProducts()
      return this.client.fetchQueryProducts({ collection_id: 264503171 })
        .then((fetchedProducts) => {
          let loadProducts = () => {
            // Create Product models using the data fetched and fill products array
            let products = []
            _.forEach(fetchedProducts, (product) => {
              products.push(new Product(product))
            })
            this.products = products
          }
          return $q.when(loadProducts())
        })
    }

    /**
     * Populates the cartItems array
     * Returns a promise
     */
    _populateCartItems () {
      let loadCartItems = () => {
        let lineItems = this.cart.lineItems
        let cartItems = []
        _.forEach(lineItems, (lineItem) => {
          cartItems.push(new CartItem(lineItem))
        })
        this.cartItems = cartItems
      }
      return $q.when(loadCartItems())
    }

    /**
     * Return the cart item that matches the passed variant id
     */
    _findCartItemByVariantId (variantId) {
      return this.cart.lineItems.filter(function (item) {
        return (item.variant_id === variantId)
      })[0]
    }
  }

  return new ShopifyManager()
})

/*global angular*/
'use strict'

angular.module('pizzahunt').directive('phCartTab', function (shopifyManager, $rootScope) {
  return {
    replace: true,
    scope: {
      count: '=',
      clickHandler: '='
    },
    templateUrl: 'components/ph-cart-tab/ph-cart-tab.html',
    bindToController: true,
    controllerAs: 'cartTabCtrl',
    controller: function ($scope) {
      let cartTabCtrl = this

      cartTabCtrl.openCart = () => {
        cartTabCtrl.clickHandler()
      }
    }
  }
})

/*global angular, requestAnimationFrame*/
'use strict'

const CURSOR_CONTAINER_CLASS = 'ph-pizza-cursor-container'

// Very roughly adapted from http://codepen.io/tholman/full/rxJpdQ
angular.module('pizzahunt').directive('phPizzaCursor', function () {
  return {
    restrict: 'A',
    scope: {
      containCursors: '='
    },
    controller: function ($element, $scope) {
      // OHHHHHHYEAH EMOJI PIZZA
      var emoji = '🍕'
      var width = window.innerWidth
      var height = window.innerHeight
      var cursor = {
        x: width / 2,
        y: height / 2
      }
      var particles = []
      var count = 0
      var $container = angular.element(document.getElementsByClassName(CURSOR_CONTAINER_CLASS)[0])

      // Put cursor elements within a cursor container if indicated otherwise fallback to use
      // element itself as the cursor element container
      if ($scope.containCursors || $container.length === 0) {
        $container = $element
      }

      function onWindowResize (e) {
        width = window.innerWidth
        height = window.innerHeight
      }

      function onMouseMove (e) {
        cursor.x = e.pageX
        cursor.y = e.pageY

        count = count + 1
        if (count > 5) {
          addParticle(cursor.x, cursor.y, emoji)
          count = 0
        }
      }

      function onTouchMove (e) {
        if (e.touches.length > 0) {
          for (var i = 0; i < e.touches.length; i++) {
            addParticle(e.touches[i].clientX, e.touches[i].clientY, emoji)
          }
        }
      }

      function addParticle (x, y, character) {
        var particle = new Particle()
        particle.init(x, y, character)
        particles.push(particle)
      }

      function updateParticles () {
        // Updated
        for (var i = 0; i < particles.length; i++) {
          particles[i].update()
        }

        // Remove dead particles
        for (var j = particles.length - 1; j >= 0; j--) {
          if (particles[j].lifeSpan < 0) {
            particles[j].die()
            particles.splice(j, 1)
          }
        }
      }

      function loop () {
        requestAnimationFrame(loop)
        updateParticles()
      }
      loop()

      $element.bind('mousemove', function (event) {
        onMouseMove(event)
      })
      $element.bind('touchmove', function (event) {
        onTouchMove(event)
      })
      $element.bind('touchstart', function (event) {
        onTouchMove(event)
      })

      window.addEventListener('resize', onWindowResize)

      function Particle () {
        this.lifeSpan = 120 // ms
        this.initialStyles = {
          'position': 'absolute',
          'display': 'block',
          'pointerEvents': 'none',
          'z-index': '10000000',
          'fontSize': '16px'
        }

        // Init, and set properties
        this.init = function (x, y, character) {
          this.velocity = {
            x: (Math.random() < 0.5 ? -1 : 1) * (Math.random() / 2),
            y: 1
          }

          this.position = {
            x: x - 10,
            y: y - 20
          }

          this.element = document.createElement('span')
          this.element.innerHTML = character
          applyProperties(this.element, this.initialStyles)
          this.update()
          $container.prepend(this.element)
        }

        this.update = function () {
          this.position.x += this.velocity.x
          this.position.y += this.velocity.y
          this.lifeSpan--
          this.element.style.transform = 'translate3d(' + this.position.x + 'px,' + this.position.y + 'px,0) scale(' + (this.lifeSpan / 120) + ')'
        }

        this.die = function () {
          this.element.parentNode.removeChild(this.element)
        }
      }

      // Util to apply css `properties` to an element.
      function applyProperties (target, properties) {
        for (var key in properties) {
          target.style[key] = properties[key]
        }
      }
    }
  }
})

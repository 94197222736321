/*global angular, ShopifyBuy*/
'use strict'

import extend from 'lodash/object/extend'

/**
 * Product model – Represents the Product to buy
 */
angular.module('pizzahunt').factory('Product', function (textService) {
  function Product (config) {
    config = config || {}

    // Selected variant
    this.selectedVariant = config.selectedVariant
    this.selectedVariantImage = config.selectedVariantImage

    // Info from selected variant
    this.selectedVariantId = this.selectedVariant.id
    this.imageSrc = (this.selectedVariantImage ? this.selectedVariantImage.src : ShopifyBuy.NO_IMAGE_URI)
    this.variantTitle = this.selectedVariant.title
    this.variantPrice = this.selectedVariant.price

    // @todo what are current options?
    this.currentOptions = config.options
    this.productTitle = config.title

    // Removes all other html other than the string within the <p>
    // We create the description this specific way in Shopify for the purpose of this hax
    this.productDescription = textService.removeReturnCharacter(config.description)
      .split('<meta charset="utf-8"><p>')
      .pop()
      .split('</p>')[0]

    // Splits description by paragraphs split by `/` character
    // We create the description this specific way in Shopify for the purpose of this hax
    this.productDescriptionParagraphs = this.productDescription.split('/')

    // We are not worrying about this for now
    // this.variantSelectors

    // Hax
    this.price = this.variantPrice
    this.formattedPrice = textService.formatAsMoney(this.price)
  }

  extend(Product.prototype, {
    // no methods for now
  })

  return Product
})

/*global angular*/
'use strict'

// Declare module
angular.module('pizzahunt', [
  'ph.templates'
])

// Require all the module files
require('./components/ph-components')
require('./models/ph-models')
require('./controllers/ph-controllers')
require('./services/ph-services')

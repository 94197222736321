/*global angular*/
'use strict'

/**
 * Product directive - represents the display of a product to buy
 */
angular.module('pizzahunt').directive('phProduct', function (shopifyManager) {
  return {
    replace: true,
    transclude: true,
    scope: {
      product: '='
    },
    templateUrl: 'components/ph-product/ph-product.html',
    bindToController: true,
    controllerAs: 'productCtrl',
    controller: function ($scope, $element) {
      let productCtrl = this

      productCtrl.soldOut = !(productCtrl.product.selectedVariant.attrs.product.attrs.available)

      if (productCtrl.soldOut) {
        $element.addClass('ph-product-sold-out');
      }

      // Add to cart
      productCtrl.addToCart = () => {
        let selectedVariant = productCtrl.product.selectedVariant
        shopifyManager.addToCart(selectedVariant)
          .then(() => {
            $scope.$emit('add-to-cart')
          })
      }
    }
  }
})

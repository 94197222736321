/*global angular*/
'use strict'

/**
 * Service providing utility functions for elements
 */
angular.module('pizzahunt').service('elementService', function () {
  /**
   * Gets the pre set classes of an element.
   *
   * @method getClasses
   * @param  {Element} element
   *         The element to get the classes from.
   *
   * @return {Array} Collection of classes already set on the element.
   */
  this.getClasses = (element) => {
    const elClasses = element.attr('class')

    return elClasses ? elClasses.split(' ') : []
  }

  /**
   * Gets all the attributes with the passed name from the component element.
   *
   * @method getComponentAttrs
   * @param  {Object} attr
   *         The attributes of a component.
   *
   * @param  {String} name
   *         The name of the component attribute.
   *
   * @return {Array} Collection of attributes.
   */
  this.getComponentAttrs = (attr, name) => {
    const attribute = attr[name]
    let attributes

    if (attribute) {
      attributes = attribute.split(' ')
    }

    return attributes
  }
})
